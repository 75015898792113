import React, { ReactElement } from 'react'
import './AboutUsMobileNavigation.scss'
import Header from '../../components/Header/Header'
import VerticalNavigation from '../../components/VerticalNavigation/VerticalNavigation'
import ABOUT_US_NAVIGATION from '../../data/about-us-navigation.json'
import Seo from '../../components/Seo/Seo'

const AboutUsMobileNavigation = (): ReactElement => {
  return (
    <>
      <Header />
      <Seo title="About Us | Navigation" />
      <div className="about-us-mobile-navigation">
        <h2 className="about-us-mobile-navigation-title">About Us</h2>
        <VerticalNavigation
          navigations={ABOUT_US_NAVIGATION}
          variant="secondary"
        />
      </div>
    </>
  )
}

export default AboutUsMobileNavigation
